@use '../abstracts' as *;

.content-image {
	margin-block-end: var(--m-content-element);
	figure {
		text-align: center;
	}
	figcaption {
		text-align:    left;
		display:       inline-block;
		padding-block: var(--distance-300);
	}
}





