@use '../abstracts' as *;

// Galerie
.content-gallery {
	--_gap-gallery:   var(--gap-gallery, 1rem);
	margin-block-end: var(--m-content-element);
	ul {
		display:                grid;
		grid-template-columns:  repeat(auto-fit, minmax(300px, 1fr));
		gap:                    var(--_gap-gallery);
		margin:                 0;
		padding:                0;
		list-style:             none;
	}
	figure {
		margin: 0;
	}
}

// Content Galerie für Siegersujets
.content-gallery--winner {
	--_gap-gallery: var(--gap-gallery-winner);
	display:        contents;
	ul {
		display:     contents;
		grid-column: var(--col-start, 2) / span var(--col-width, var(--col-count));
		li {
			@include max-xsmall {
				grid-column: 2 / -2;
			}
			grid-column: var(--col-start) / span var(--col-width);
			margin-block-end: var(--m-content-element);
		}
		@include small {
			:first-child {
				--col-start: 5;
				--col-width: 6;
			}
			:nth-child(2) {
				--col-start: 2;
				--col-width: 5;
			}
			:nth-child(3) {
				--col-start: 9;
				--col-width: 5;
			}
		}
	}
	figure {
		display:               grid;
		gap:                   var(--gap-base);
		&:has(figcaption) {
			grid-template-rows: max-content 1fr;
		}
	}
	figcaption {
		font-weight: var(--fw-bold);
		font-size: var(--fs-600);
		text-align: center;
	}
}

// Content Galerie für Sponsoren
.content-gallery--sponsoren {
	--_gap-gallery: var(--gap-gallery-sponsoren);
	ul {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	}
	figure {
		height:        100%;
		width:         100%;
		display:       grid;
		align-items:   center;
		justify-items: center;
	}
}

// Content Galerie für Fotos
.content-gallery--fotos {
	--_gap-gallery: var(--gap-gallery-fotos);
	ul {
		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	}
	figure {
		height:     100%;
		width:      100%;
		object-fit: contain;
	}
	figcaption {
		display: none
	}
}