@use '../abstracts' as *;

.bg-primary {
	background:    var(--primary);
	color:         var(--neutral-00);
	padding:       var(--distance-800) var(--p-base) ;
	border-radius: var(--br-base, 0.5rem);
	@for $index from 1 through 6 {
		h#{$index},
		.text--h#{$index} {
			--_color-headline: var(--secondary);
		}
	}
	a {
		color: var(--neutral-00);
		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
		}
	}
}

.bg-secondary {
	background:    var(--secondary);
	color:         var(--clr-base);
	padding:       var(--distance-800) var(--p-base) ;
	border-radius: var(--br-base, 0.5rem);
	@for $index from 1 through 6 {
		h#{$index},
		.text--h#{$index} {
			--_color-headline: var(--primary);
		}
	}
	a {
		color: var(--clr-base);
		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
		}
	}
}