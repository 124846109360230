@use '../abstracts' as *;

// Accordion Handorgel
.content-accordion {
	margin-block-end: var(--m-content-element);
}

.handorgel__header {
	background: var(--bg-handorgel-header);
	color:      var(--clr-handorgel-header);

	button {
		background:      transparent;
		display:         flex;
		flex-flow:       row;
		justify-content: space-between;

		&::after {
			content:     "\f0a6";
			font-family: FontAwesome;
			font-size:   2.4rem;
			line-height: 1;
			transform:   rotate(90deg);
			transition:  transform 0.5s;
		}
	}
}

.handorgel__header--open {
	button {
		&::after {
			transform: rotate(180deg);
		}
	}
}

.handorgel__content {
	background: transparent;
}
.handorgel__content--open {
	transition: height 1.5s ease-in-out;
}

.handorgel__content__inner {
	padding-inline: 0;
	opacity: 1;
	transition: unset;
	&.handorgel__content--opened {
		opacity: 1;
		transition: unset;
	}
}