@use '../abstracts/' as *;

#header {
	.inside {
		width: var(--w-header);
		max-width: var(--w-max-header);
		margin-inline: auto;
		display: grid;
		grid-template-rows: max-content max-content;
		justify-items: center;
	}
	padding-block-end: var(--p-header);
}