@use '../abstracts' as *;

input,
textarea {
	border:           none;
	border-block-end: var(--b-form);
	padding:          var(--p-field);
	margin-block-end: var(--m-field);
}

fieldset {
	border:  none;
	padding: 0;
}

label,
legend {
	--_fw:       var(--fw-bold, 700);
	font-weight: var(--_fw);
}

.ce_form {
	background:       var(--bg-form);
	border-radius:    var(--br-form);
	padding:          var(--p-form);
	margin-block-end: var(--m-form);
}

.widget {
	display:   flex;
	flex-flow: column;
	gap:       var(--gap-form-field, 0.5rem);
}
.widget-text,
.widget-textarea {
	max-width: var(--w-max-content-field);
}

.widget-radio {
	display:          flex;
	flex-flow:        wrap;
	column-gap:       2rem;
	margin-block-end: var(--m-form);
	fieldset {
		display: contents;
		legend {
			display: block;
			width:   100%;
		}
		> span {
			flex:               1 0 max-content;
			max-width: 250px;
			display:            block;
			white-space:        nowrap;
			border-block-start: var(--b-form);
			&:last-of-type {
				margin-inline-end: auto;
			}
		}
	}
	label {
		--_fw: var(--fw-base, 400);
	}
	+ .conditions {
		margin-block-start: calc(-1 * var(--m-form));
	}
}

.widget-submit {
	display: inline-block;
	margin-block-start: var(--m-field);
	button {
		background: var(--bg-button);
		color: var(--clr-button);
		font-size: var(--fs-button);
		font-weight: var(--fw-button);
		letter-spacing: var(--ls-button);
		padding: var(--p-button);
		border: none;
		border-radius: var(--br-button);
	}
}


