@use '../abstracts' as *;

:root {
	// FARBSCHEMA
	// Farben
	--primary:                                 #d4021d;
	--secondary:                               #fde900;
	--tertiary:                                #06c4e5;
	// Grauschattierungen
	--neutral-00:                              #ffffff;
	--neutral-01:                              #e6e6e6;
	--neutral-02:                              #cccccc;
	--neutral-03:                              #b3b3b3;
	--neutral-04:                              #999999;
	--neutral-05:                              #808080;
	--neutral-06:                              #666666;
	--neutral-07:                              #4d4d4d;
	--neutral-08:                              #333333;
	--neutral-09:                              #1a1a1a;
	--neutral-10:                              #000000;

	// Basis
	--clr-base:                                var(--neutral-10);
	--bg-base:                                 var(--neutral-00);

	// TYPOGRAPHIE
	// fluide Typografie mit clamp (nicht komplett barrierefrei)
	// https://css-clamp-generator.com/ - (Viewport 480-1024)
	--fs-300:                                  clamp(.75rem, .3676vw + .6397rem, .875rem); // 12-14px
	--fs-400:                                  clamp(.875rem, .3676vw + .7647rem, 1rem); // 14-16px
	--fs-500:                                  clamp(1rem, .7353vw + .7794rem, 1.25rem); // 16-20px
	--fs-600:                                  clamp(1.25rem, .7353vw + 1.0294rem, 1.5rem); // 20-24px
	--fs-700:                                  clamp(1.5rem, .7353vw + 1.2794rem, 1.75rem); // 24-28px
	--fs-800:                                  clamp(1.625rem, 1.8382vw + 1.0735rem, 2.25rem); // 26-36px
	--fs-900:                                  clamp(1.75rem, 10.2941vw + -1.3382rem, 5.25rem); // 28-84px

	// Basis
	--fs-base:                                 var(--fs-500);
	--fs-small:                                var(--fs-400);

	// Font-Family
	--ff-base:                                 'IBM Plex Sans', Verdana, Arial, sans-serif;
	--ff-alternative:                          'Spicy Rice', Helvetica, sans-serif;

	// Font-Weight
	--fw-base:                                 400;
	--fw-bold:                                 700;

	// Line-Height
	--lh-base:                                 1.5;
	--lh-small:                                1.1;
	@include large {
		--lh-base: 1.6;
	}

	// ABSTÄNDE UND ABMESSUNGEN
	--distance-100:                            clamp(.325rem, .8824vw + .0603rem, .625rem); // 5px - 10px
	--distance-200:                            clamp(.625rem, .7353vw + .4044rem, .875rem); // 10px - 14px
	--distance-300:                            clamp(.75rem, .7353vw + .5294rem, 1rem); // 12px - 16px
	--distance-400:                            clamp(.875rem, 1.1029vw + .5441rem, 1.25rem); // 14px - 20px
	--distance-500:                            clamp(1rem, 1.4706vw + .5588rem, 1.5rem); // 16px - 24px
	--distance-600:                            clamp(1.5rem, 2.2059vw + .8382rem, 2.25rem); // 24px - 36px
	--distance-700:                            clamp(1.875rem, 2.2059vw + 1.2132rem, 2.625rem); // 30px - 42px
	--distance-800:                            clamp(2.125rem, 2.9412vw + 1.2426rem, 3.125rem); // 34px - 50x
	--distance-900:                            clamp(2.5rem, 3.6765vw + 1.3971rem, 3.75rem); // 40px - 60px

	--gap-base:                                var(--distance-500);

	--p-base:                                  var(--distance-500);
	--m-base:                                  var(--distance-500);
	--b-base:                                  1px solid var(--neutral-02);

	--w-max:                                   1280px;
	@include xlarge {
		--w-base: 90vw;
	}
	--w-max-content:                           100ch;
	--w-min-content:                           45ch;

	// Base Border Settings
	--br-base:                                 6px; // Border-Radius
	// --basic-border-width:  1px;
	// --basic-border-color:  var(--neutral-01);
	// --border-width:        var(--basic-border-width);
	// --border-color:        var(--basic-border-color);
	// --border:              var(--border-width) solid var(--border-color);

	// Base Shadow Settings
	// --basic-shadow-color:  hsla(0, 0%, 0%, 0.1);
	// --shadow-color:        var(--basic-shadow-color);

	// COMPONENTEN UND LAYOUT
	//  Body
	--clr-body:                                var(--clr-base);
	--bg-body:                                 var(--secondary);
	@include xsmall {
		--bg-body: var(--secondary) url("../../images/rco_hg_1000.png") center 20px no-repeat;
	}
	@include medium {
		--bg-body: var(--secondary) url("../../images/rco_hg_1500.png") center 3px repeat-y;
	}
	@include large {
		--bg-body: var(--secondary) url("../../images/rco_hg.png") center 8px repeat-y;
	}
	--ff-body:                                 var(--ff-base);
	--fs-body:                                 var(--fs-base);
	--fw-body:                                 var(--fw-base);
	--lh-body:                                 var(--lh-base);

	// Wrapper
	--w-min:                                   320px;

	// Header
	--w-header:                                var(--w-base);
	--w-max-header:                            var(--w-max);
	--p-header:                                12px;

	// Main
	--bg-main:                                 var(--neutral-00);
	--w-main:                                  var(--w-base);
	--w-max-main:                              var(--w-max);
	--p-main:                                  var(--distance-900);
	--br-main:                                 10px;

	// Footer
	--bg-footer:                               var(--neutral-10);
	--clr-footer:                              var(--neutral-00);
	--w-footer:                                var(--w-base);
	--w-max-footer:                            var(--w-max);
	--p-footer:                                var(--distance-700);

	// 	Grid
	--col-count:                               12;

	// Navigation
	--ff-navigation:                           var(--ff-alternative);
	--fs-navigation:                           var(--fs-800);
	--fw-navigation:                           var(--fw-base);
	--ls-navigation:                           2px;
	--gap-navigation:                          var(--gap-base);
	--p-navigation:                            var(--p-base);
	--br-navigation:                           var(--br-base);
	--bg-navigation:                           var(--secondary);
	--clr-navigation:                          var(--clr-base);
	--clr-navigation-active:                   var(--primary);
	--min-width-nav-level2:                    150px;

	// Toggler Mobile
	--bg-toggler:                              var(--neutral-10);
	--clr-toggler:                             var(--neutral-00);
	--p-toggler:                               1rem;
	--br-toggler:                              var(--br-base);

	// Formular
	--bg-form:                                 var(--secondary);
	--br-form:                                 var(--br-base);
	--p-form:                                  var(--p-base);
	--m-form:                                  var(--m-content-element);
	--b-form:                                  2px solid var(--primary);
	--w-max-content-field:                     70ch;
	--p-field:                                 .625rem;
	--m-field:                                 var(--distance-200);
	--gap-form-field:                          .5rem;

	// Button
	--bg-button:                               var(--primary);
	--clr-button:                              var(--neutral-00);
	--fs-button:                               var(--fs-600);
	--fw-button:                               500;
	--ls-button:                               2px;
	--p-button:                                0.9rem 2rem;
	--br-button:                               var(--br-base);

	// Link
	--clr-link:                                var(--clr-base);
	--clr-link-visited:                        var(--neutral-05);
	--clr-link-hover:                          var(--primary);

	// Headline
	--clr-headline:                            var(--clr-base);
	--fs-h1:                                   var(--fs-900);
	--fs-h2:                                   var(--fs-800);
	--fs-h3:                                   var(--fs-700);
	--fs-h4:                                   var(--fs-600);
	--fs-h5:                                   var(--fs-500);
	--fs-h6:                                   var(--fs-500);
	--ff-headline:                             var(--ff-alternative);
	--fw-headline:                             var(--fw-base);
	--lh-headline:                             var(--lh-small);
	--m-headline:                              var(--m-base);
	--ls-headline:                             2px;

	// Inhaltselement
	--m-content-element:                       var(--distance-900);
	--w-media:                                 400px;

	// Gallery
	--gap-gallery:                             1rem;
	--gap-gallery-fotos:                       0.5rem;
	--gap-gallery-winner:                      var(--distance-700);
	--gap-gallery-sponsoren:                   var(--distance-700);

	// Slider (Swiper)
	--swiper-pagination-color:                 var(--primary);
	--swiper-navigation-color:                 var(--secondary);
	--fs-swiper-navigation:                    var(--fs-700);
	--fw-swiper-navigation:                    var(--fw-bold);
	--bg-swiper:                               var(--primary);
	--b-swiper:                                none;
	--swiper-pagination-bullet-horizontal-gap: 3px;
	@include xsmall {
		--swiper-pagination-bullet-horizontal-gap: 4px;
	}
	--swiper-pagination-bullet-width:          6px;
	--swiper-pagination-bullet-height:         6px;
	@include xsmall {
		--swiper-pagination-bullet-width:  12px;
		--swiper-pagination-bullet-height: 12px;
	}

	// Akkordeon
	--bg-handorgel-header:                     var(--primary);
	--clr-handorgel-header:                    var(--secondary);

	// Top-Link
	--bg-toplink:                              var(--tertiary);
	--clr-toplink:                             var(--neutral-00);
	--w-max-toplink:                           84px;
	--br-toplink:                              50%;
	--p-toplink:                               22px 26.2px;

	// 	Logo
	--w-logo:                                  240px;
	@include large {
		--w-logo: 410px;
	}

	// Countdown
	--fs-countdown:                            clamp(1.5rem, 4.7794vw + .0662rem, 3.125rem);
	--p-countdown:                             clamp(.5rem, 2.2059vw + -.1618rem, 1.25rem);
	--gap-countdown:                           var(--distance-300);
}
