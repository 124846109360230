@use '../abstracts' as *;

.content-toplink {
	background:         var(--bg-toplink);
	color:              var(--clr-toplink);
	max-width: var(--w-max-toplink);
	border-radius:      var(--br-toplink);
	margin-inline: auto;
	margin-block-end: var(--m-content-element);
	@include medium {
		margin: 0;
		position:           fixed;
		bottom:             200px;
		right:              5vw;
		z-index:            900;
		animation:          scroll-toplink;
		animation-timeline: scroll();
	}

	a {
		text-decoration: none;
		display:         block;

		padding:         var(--p-toplink);
		&::before {
			content:     "\f0a6";
			font-family: FontAwesome;
			font-size:   2.4rem;
			line-height: 1;
		}
	}
}

@supports not (animation-timeline: scroll()) {
	@include medium {
		.content-toplink {
			opacity: 0;

			&.content-toplink--visible {
				opacity:   1;
				animation: opacity-toplink 700ms;
			}
		}
	}
}

@keyframes scroll-toplink {
	0% {
		opacity: 0;
	}
	9% {
		opacity: 1;
	}
}

@keyframes opacity-toplink {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}