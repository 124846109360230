@use '../abstracts' as *;

#main {
	background:    var(--bg-main);
	width:         var(--w-main);
	height: 100%;
	max-width:     var(--w-max-main);
	border-top:    7px var(--clr-base) dotted;
	border-right:  7px var(--clr-base) dotted;
	border-bottom: 0;
	border-left:   7px var(--clr-base) dotted;
	border-radius: var(--br-main) var(--br-main) 0 0;
	padding-block: var(--p-main);
	margin-inline: auto;
}