@use 'breakpoints' as *;

// em verwenden, da rem in media-queries problematisch in manchen Browsern
// px als Orientierung bei Bezugsschriftgroesse 16px
$b-xxsmall:     20em !default; // 320px smartphone (portrait)
$b-xxsmall-max: 29.999em !default;
$b-xsmall:      30em !default; // 480px smartphone (landscape)
$b-xsmall-max:  37.499em !default;
$b-small:       37.5em !default; // 600px small tablet
$b-small-max:   47.999em !default;
$b-medium:      48em !default; // 768px tablet (portrait)
$b-medium-max:  63.999em !default;
$b-large:       64em !default; // 1024px tablet (landscape)
$b-large-max:   89.999em !default;
$b-xlarge:      90em !default; // 1440px desktop
$b-xlarge-max:  119.999em !default;
$b-xxlarge:     120em !default; // 1920em

// variable Breiten
@mixin min($b-min) { // min-width
  @media only screen and (min-width: #{$b-min}) {
	@content;
  }
}

@mixin max($b-max) { // max-width
  @media only screen and (max-width: #{$b-max}) {
	@content;
  }
}

@mixin only($b-min, $b-max) { // min-width bis max-width
  @media only screen and (min-width: #{$b-min}) and (max-width: #{$b-max}) {
	@content;
  }
}

// ab einer Breite
@mixin xxsmall {
  @media only screen and (min-width: #{$b-xxsmall}) {
	@content;
  }
}

@mixin xsmall {
  @media only screen and (min-width: #{$b-xsmall}) {
	@content;
  }
}

@mixin small {
  @media only screen and (min-width: #{$b-small}) {
	@content;
  }
}

@mixin medium {
  @media only screen and (min-width: #{$b-medium}) {
	@content;
  }
}

@mixin large {
  @media only screen and (min-width: #{$b-large}) {
	@content;
  }
}

@mixin xlarge {
  @media only screen and (min-width: #{$b-xlarge}) {
	@content;
  }
}

@mixin xxlarge {
  @media only screen and (min-width: #{$b-xxlarge}) {
	@content;
  }
}

// bis zu Breite
@mixin max-xxsmall {
  @media only screen and (max-width: #{$b-xxsmall-max}) {
	@content;
  }
}

@mixin max-xsmall {
  @media only screen and (max-width: #{$b-xsmall-max}) {
	@content;
  }
}

@mixin max-small {
  @media only screen and (max-width: #{$b-small-max}) {
	@content;
  }
}

@mixin max-medium {
  @media only screen and (max-width: #{$b-medium-max}) {
	@content;
  }
}

@mixin max-large {
  @media only screen and (max-width: #{$b-large-max}) {
	@content;
  }
}

@mixin max-xlarge {
  @media only screen and (max-width: #{$b-xlarge-max}) {
	@content;
  }
}



//auschliesslich ein Bereich
@mixin only-xxsmall {
  @media only screen and (min-width: #{$b-xxsmall}) and (max-width: #{$b-xxsmall-max - 1px}) {
	@content;
  }
}

@mixin only-xsmall {
  @media only screen and (min-width: #{$b-xsmall}) and (max-width: #{$b-xsmall-max}) {
	@content;
  }
}

@mixin only-small {
  @media only screen and (min-width: #{$b-small}) and (max-width: #{$b-small-max}) {
	@content;
  }
}

@mixin only-medium {
  @media only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) {
	@content;
  }
}

@mixin only-large {
  @media only screen and (min-width: #{$b-large}) and (max-width: #{$b-large-max}) {
	@content;
  }
}

@mixin only-xlarge {
  @media only screen and (min-width: #{$b-xlarge}) and (max-width: #{$b-xlarge-max}) {
	@content;
  }
}
