@use '../abstracts' as *;

.color-primary {
	color       : var(--primary);
	font-weight : var(--fw-bold);
}

.color-secondary {
	color       : var(--secondary);
	font-weight : var(--fw-bold);
}