// Body
// [1] Force scrollbars to always be visible to prevent awkward ‘jumps’ when
//     navigating between pages that do/do not have enough content to produce
//     scrollbars naturally.
// [2] Ensure the page always fills at least the entire height of the viewport.
// [3] Prevent certain mobile browsers from automatically zooming fonts.
body {
	background:               var(--bg-body);
	color:                    var(--clr-body);
	// noinspection CssNoGenericFontName
	font-family:              var(--ff-body);
	font-size:                var(--fs-body);
	font-weight:              var(--fw-body);
	line-height:              var(--lh-body);
	overflow-y:               scroll; // [1]
	min-height:               100%; // [2]
	-webkit-text-size-adjust: none; // [3]
	-ms-text-size-adjust:     none; // [3]
	@media print {
		background: transparent none;
		font-size:  12pt;
	}
}

// alle Überschriften und Text der optisch wie Überschrift formatiert ist
@for $index from 1 through 6 {
	h#{$index},
	.text--h#{$index} {
		--_m-headline:     var(--m-headline, 1rem);
		--_color-headline: var(--clr-headline, #000000);
		color:             var(--_color-headline);
		// noinspection CssNoGenericFontName
		font-family:       var(--ff-headline);
		font-weight:       var(--fw-headline);
		line-height:       var(--lh-headline);
		letter-spacing:    var(--ls-headline);
		hyphens:           unset;
		text-wrap:         balance;
		text-align:        center;
		margin-block-end:  var(--_m-headline);
	}
}

h1,
.text--h1 {
	font-size:     var(--fs-h1);
	--_m-headline: var(--distance-900);
}

h2,
.text--h2 {
	--_color-headline: var(--primary);
	--_m-headline:     var(--distance-600);
	font-size:         var(--fs-h2);
}

h3,
.text--h3 {
	--_color-headline: var(--neutral-06);
	font-size:         var(--fs-h3);
}

// Text
p {
	margin-block-end: var(--m-base);
	text-wrap:        pretty;
}

small,
.text-small {
	font-size: var(--fs-small);
}

// Hervorhebungen
strong {
	font-weight: var(--fw-bold);
}

// Link
a {
	color:           var(--clr-link);
	text-decoration: underline;
	&:visited {
		color: var(--clr-link-visited);
	}
	&:hover,
	&:focus {
		color: var(--clr-link-hover);
	}
	&:active {
		color:           var(--clr-link-hover);
		text-decoration: none;
	}
}
