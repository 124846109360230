@use '../abstracts' as *;

.content-text {
	--_w-media:       var(--w-media, 30%);
	margin-block-end: var(--m-content-element);
	:last-child {
		margin-block-end: 0;
	}
	figure {
		display:               grid;
		gap:                   var(--gap-base);
		grid-template-columns: clamp(300px, var(--_w-media), 1fr);
		&:has(figcaption) {
			grid-template-rows: max-content 1fr;
		}
		@include xsmall {
			max-width: var(--_w-media);
		}
	}
}

//  Bild oben/unten
.media--above,
.media--below {
	display:   flex;
	flex-flow: column;
	&.text-center {
		figure {
			align-self: center;
		}
	}
	&.media--small {
		--_w-media: 300px;
	}
	&.media--large {
		--_w-media: 500px;
	}
	&.media--xlarge {
		--_w-media: 600px;
	}
	&.media--xxlarge {
		--_w-media: 700px;
	}
	&.media--xxxlarge {
		--_w-media: 800px;
	}
	&.media--fullwidth {
		--_w-media: 100%;
	}
}

.media--above {
	figure {
		align-self: flex-start;
		margin:     0 0 var(--m-base) 0;
	}
}

.media--below {
	figure {
		align-self: flex-start;
		margin:     var(--m-base) 0 0 0;
	}
}

// Bild Links/Rechts
.media--left,
.media--right {
	display:   flex;
	flex-flow: column;
	@include medium {
		display: block;
	}
	&.media--small {
		--_w-media: 300px;
	}
	&.media--large {
		--_w-media: 500px;
	}
	figure {
		align-self: flex-start;
		margin:     0 0 var(--m-base) 0;
	}
}

.media--left {
	figure {
		@include medium {
			float:  left;
			margin: 0 var(--distance-900) var(--m-base) 0;
		}
	}
}

.media--right {
	figure {
		@include medium {
			float:  right;
			margin: 0 0 var(--m-base) var(--distance-900);
		}
	}
}





