@use '../abstracts' as *;

#footer {
	background: var(--bg-footer);
	color:      var(--clr-footer);
	text-align: center;
	.inside {
		width:         var(--w-header);
		max-width:     var(--w-max-header);
		margin-inline: auto;
		padding-block: var(--p-footer);
	}
	@for $index from 1 through 6 {
		h#{$index} {
			--_m-headline: 1rem;
			color:         var(--secondary);
			font-size:     var(--fs-600);
		}
	}
	a {
		color: var(--neutral-00);
		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
		}
	}
}

.footer-content {
	--m-content-element:   var(--m-base);
	display:               grid;
	gap:                   var(--gap-base);
	grid-template-columns: minmax(var(--distance-500), 1fr) repeat(12, minmax(0, 5rem)) minmax(var(--distance-500), 1fr);
	> * {
		grid-column: 2 / -2;
		display: grid;
		grid-template-rows: subgrid;
		grid-row: span 2;
		@include medium {
			&:first-child {
				grid-column: 2 / 8;
			}
			&:nth-child(2) {
				grid-column: 8 / -2;
			}
		}
		@include min(69em) {
			&:first-child {
				grid-column: 2 /6;
			}
			&:nth-child(2) {
				grid-column: 6 / 10;
			}
			&:nth-child(3) {
				grid-column: 10 / -2;
			}
		}
	}
}

