@use '../abstracts' as *;

// Content-Slider (Swiper)
.content-swiper {
	margin-block-end: var(--m-content-element);
	.content-image {
		margin: 0;
	}
}

.swiper {
	display:               grid;
	row-gap:               1rem;
	grid-template-columns: max-content 1fr max-content;
	grid-template-rows: max-content max-content;
}

.swiper-wrapper {
	grid-column: 1 / -1;
	grid-row:    1;
}

.swiper-button-next,
.swiper-button-prev {
	display:            none;
	background:         var(--bg-swiper);
	position:           unset;
	align-self:         center;
	width:              calc(.8 * var(--swiper-navigation-size));
	border:             var(--b-swiper);
	padding:            0;
	grid-row:           2;
	margin-block-start: 0;
	&::after {
		font-size:   var(--fs-swiper-navigation);
		font-weight: var(--fw-swiper-navigation);
		display:     block;
	}
	@include xsmall {
		display: block;
		z-index: 100
	}
}

.swiper-button-next {
	grid-column: 3;
}

.swiper-button-prev {
	grid-column: 1;
}

.swiper-pagination {
	position:    unset;
	align-self:  center;
	grid-column: 1 / -1;
	grid-row:    2;
}
