@use '../abstracts' as *;

.main-grid {
	display:               grid;
	column-gap:            var(--gap-base);
	grid-template-columns: minmax(var(--distance-500), 1fr) repeat(var(--column-count, 12), minmax(0, 4rem)) minmax(var(--distance-500), 1fr);
	> * {
		grid-column:           1 / -1;
		display:               grid;
		grid-template-columns: subgrid;
	}
	> * > * {
		grid-column: 2 / -2;
		@include small {
			grid-column: var(--col-start, 2) / span var(--col-width, var(--col-count));
		}
	}
}

@include small {
	// SPALTEN
	// Spalten-Anzahl
	.col-width-s-2 {
		--col-width: 2;
	}
	.col-width-s-3 {
		--col-width: 3;
	}
	.col-width-s-4 {
		--col-width: 4;
	}
	.col-width-s-5 {
		--col-width: 5;
	}
	.col-width-s-6 {
		--col-width: 6;
	}
	.col-width-s-7 {
		--col-width: 7;
	}
	.col-width-s-8 {
		--col-width: 8;
	}
	.col-width-s-9 {
		--col-width: 9;
	}
	.col-width-s-10 {
		--col-width: 10;
	}
	.col-width-s-11 {
		--col-width: 11;
	}

	// Column Start
	.col-start-s-2 {
		--col-start: 2;
	}
	.col-start-s-3 {
		--col-start: 3;
	}
	.col-start-s-4 {
		--col-start: 4;
	}
	.col-start-s-5 {
		--col-start: 5;
	}
	.col-start-s-6 {
		--col-start: 6;
	}
	.col-start-s-7 {
		--col-start: 7;
	}
	.col-start-s-8 {
		--col-start: 8;
	}
	.col-start-s-9 {
		--col-start: 9;
	}
	.col-start-s-10 {
		--col-start: 10;
	}
	.col-start-s-11 {
		--col-start: 11;
	}
}

@include large {
	// SPALTEN
	// Spalten-Anzahl
	.col-width-2 {
		--col-width: 2;
	}
	.col-width-3 {
		--col-width: 3;
	}
	.col-width-4 {
		--col-width: 4;
	}
	.cos-widt--5 {
		--col-width: 5;
	}
	.col-width-6 {
		--col-width: 6;
	}
	.col-width-7 {
		--col-width: 7;
	}
	.col-width-8 {
		--col-width: 8;
	}
	.col-width-9 {
		--col-width: 9;
	}
	.col-width-10 {
		--col-width: 10;
	}
	.col-width-11 {
		--col-width: 11;
	}

	// Columns Start
	.col-start-2 {
		--col-start: 2;
	}
	.col-start-3 {
		--col-start: 3;
	}
	.col-start-4 {
		--col-start: 4;
	}
	.col-start-5 {
		--col-start: 5;
	}
	.col-start-6 {
		--col-start: 6;
	}
	.col-start-7 {
		--col-start: 7;
	}
	.col-start-8 {
		--col-start: 8;
	}
	.col-start-9 {
		--col-start: 9;
	}
	.col-start-10 {
		--col-start: 10;
	}
	.col-start-11 {
		--col-start: 11;
	}
}

