@use '../abstracts' as *;

#wrapper {
	display:            grid;
	min-height:         100vh;
	min-width: var(--w-min);
	@supports ( min-height: 100dvh) {
		min-height: 100dvh;
	}
	grid-template-rows: auto 1fr auto;
}
