/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing : border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
	margin        : 0;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior : smooth;
}

/* Make images easier to work with */
/* alte Variante
img,
picture,
svg,
canvas {
  max-width: 100%;
  display: block;
  height         : auto;
}
*/
img,
picture,
svg,
video,
canvas,
audio {
	max-width      : 100%;
	height         : auto;
	vertical-align : middle;
	font-style     : italic; /* für Alt-Texte, die bei fehlenden Bildern angezeigt werden */
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font : inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion : reduce) {
	html:focus-within {
		scroll-behavior : auto;
	}

	*,
	*::before,
	*::after {
		animation-duration        : 0.01ms !important;
		animation-iteration-count : 1 !important;
		transition-duration       : 0.01ms !important;
		scroll-behavior           : auto !important;
	}
}