

// nur für Screenreader und Tastaturnutzer sichtbar
.invisible:not(:focus):not(:active) {
  position:    absolute;
  overflow:    hidden;
  clip:        rect(0 0 0 0);
  width:       1px;
  height:      1px;
  white-space: nowrap;
  clip-path:   inset(50%);
}

// nur für Screenreader
.hide {
  position:    absolute;
  overflow:    hidden;
  clip:        rect(0 0 0 0);
  width:       1px;
  height:      1px;
  white-space: nowrap;
  clip-path:   inset(50%);
}







