@use '../abstracts' as *;

// Navigation allgemein
nav,
.nav {
	position: relative;
	ul {
		margin:     0;
		padding:    0;
		list-style: none;
	}
	a,
	a:hover {
		text-decoration: none;
	}
	a:visited {
		color: var(--clr-navigation);
	}
	@media print {
		display: none;
	}
}

// Hauptnavigation (zwei Ebenen)
.nav--main {
	font-family:    var(--ff-navigation);
	font-size:      var(--fs-navigation);
	font-weight:    var(--fw-navigation);
	letter-spacing: var(--ls-navigation);
	display:        none;
	@include large {
		display: block;
	}
	strong {
		font-weight: var(--fw-navigation);
		color:       var(--clr-navigation-active);
	}
	a {
		&:is(:hover, :focus),
		&.trail {
			color: var(--clr-navigation-active);
		}
	}
	.level_1 {
		display:         flex;
		justify-content: center;
		gap:             var(--gap-base);
		li:hover {
			.level_2 {
				display: block;
			}
		}

	}
	.level_2 {
		font-size:     var(--fs-600);
		display:       none;
		background:    var(--bg-navigation);
		position:      absolute;
		z-index:       500;
		width:         min-content;
		padding:       0 var(--p-navigation);
		border-radius: 0 0 var(--br-navigation) var(--br-navigation);
	}
}

// Mobile mmenu
.nav--toggler {
	@include large {
		display: none;
	}
	a {
		display:         flex;
		gap:             1rem;
		background:      var(--bg-toggler);
		color:           var(--clr-toggler);
		padding:         var(--p-toggler);
		border-radius:   var(--br-toggler);
		font-size:       1.5rem;
		line-height:     1;
		text-decoration: none;
	}
}

// Aufblitzen des mmenu beim Laden der Seite verhindern
#mmenu:not( .mm-menu ) {
	display: none;
}

.mmenu {
	@include large {
		display: none;
	}
}

//  Metanavigation

.nav--meta {
	margin-block-end: var(--m-content-element);
	a {
		text-decoration: underline;
	}
	ul {
		@include small {
			display: flex;
			gap: var(--gap-base);
			justify-content: center;
		}
	}
}


