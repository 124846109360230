@use '../abstracts' as *;

// Markdown
.content-template {
	margin-block-end: var(--m-content-element);
}

// Markdown-mit Countdown-Zähler

#clockdiv {
	display:         flex;
	flex-flow:       column;
	justify-content: center;
	text-align:      center;
	gap:             var(--gap-countdown);
	@include xsmall {
		flex-flow: row;
	}
	> div {
		background:    var(--secondary);
		margin-inline: auto;
		padding:       8px;
		border-radius: 3px;
		@include xsmall {
			margin: 0;
		}
	}
	.days,
	.hours,
	.minutes,
	.seconds {
		background:    var(--primary);
		color:         var(--neutral-00);
		display:       block;
		font-size:     var(--fs-countdown);
		font-weight:   var(--fw-bold);
		line-height:   1;
		padding:       var(--p-countdown);
		border-radius: 3px;
	}
	.smalltext {
		font-size:     var(--fs-small);
		font-weight:   var(--fw-base);
		padding-block: 8px;
	}
}