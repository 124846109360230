@use '../abstracts' as *;

.content-download {
	padding:          0;
	margin-block-end: var(--m-content-element);
	&.ext-pdf {
		background: none;
		a {
			background:   url(../../images/iconPDF.svg) left center no-repeat;
			margin-left:  1.25rem;
			padding-left: 1.375rem;
		}
	}
}

.content-downloads {
	margin-block-end: var(--m-content-element);
	ul {
		padding: 0;
		li {
			margin:  0;
			padding: 0;
			&.ext-pdf {
				background: none;
				a {
					background:   url(../../images/iconPDF.svg) left center no-repeat;
					margin-left:  1.25rem;
					padding-left: 1.375rem;
				}
			}
		}
	}
}